.home b {
font-family: 'Clear Sans';
font-weight: normal;
font-size: 14;
color: #3434c8;
}

.home-block {
	background-color: #3434c8;
	padding-top: 60;
	padding-bottom: 60;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-size: cover;
}

.home-block-horizontal {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	justify-items: center;
	max-width: 1060;
	width: 100%;
}

.home-block-sus {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	justify-items: center;
	max-width: 1060;
	width: 100%;
}

.home-form {
	display: flex;
	flex-direction: row;
	margin-top: 40;
}

.home-form-fields {
	display: flex;
	flex-direction: column;
}

::placeholder {
	opacity: 1;
	font-family: 'Clear Sans';
	font-size: 14;
	color: grey;
}


.home-field {
	margin: 7;
	height: 46;
	width: 300;
	padding-left: 17;
	padding-right: 17;
}

.home-message-field {
	margin: 7;
	width: 300;
	padding: 8;
}

.home-form-submit {
	margin: 7;
	background-color: #cce9f4;
	border: none;
	font-size: 14;
	padding-left: 8;
	padding-right: 8;
	color: black;
	font-family: 'Clear Sans';
}

.home-form-submitted {
	height: 242;
	font-family: 'Clear Sans';
	font-size: 16;
	color: white;
	display: flex;
	align-items: center;
}

.home-sus-copy {
	flex: 0.5;
	align-items: flex-start;
	text-align: left;
}	

.home-sus-logos {
	display: flex;
	align-items: center;
	align-self: flex-start;
	flex: 0.5;
	flex-direction: column;
}

.home-sus-logo {
	max-width: 150;
	margin-top: 20;
	margin-bottom: 20;
}


@media only screen and (max-width: 860px) {
	.home-carousel {
		max-width:100%;
		height:400;
	}

	.home-hero-bg {
		width: 860;
	}

	.home-block-horizontal {
		display: grid;	
		justify-content: inherit;
		grid-template-columns: 1fr 1fr;
	}

	.home-sus-copy {
		margin-left: 20;
	}	
}


@media only screen and (max-width: 760px) {
	.home-block-horizontal {
		display: flex;
		flex-direction: column;
	}

	.home-sus-logos {
		align-self: center;
		margin-top: 20;
	}

	.home-sus-copy {
		margin-left: 22;
		margin-right: 22;
	}	

	.home-form {
		flex-direction: column;
	}

	.home-form-fields {
		flex-direction: column;
	}

	.home-field {
		width: auto;
	}

	.home-message-field {
		width: auto;
	}

	.home-form-submit {
		height: 40;
	}

	.home-block-sus {
		display: inline-block;
	}
}