@font-face {
  font-family: Source Code Pro;
  src:url("./app/assets/fonts/TestPitchSans-Semibold.otf");
}

@font-face {
  font-family: Clear Sans;
  src:url("./app/assets/fonts/ClearSans-Regular.ttf");
	font-weight: normal;
}

@font-face {
  font-family: Clear Sans;
  src:url("./app/assets/fonts/ClearSans-Bold.ttf");
	font-weight: bold;
}


h1, p, h2, h3, h4, h5, h6 {
  font-family: 'Clear Sans';
}

h1 {
	color: white;
	font-weight: bold !important;
	letter-spacing: 1.3;
	font-size: 30px !important;
}

p {
	text-align: left;
	font-size: 16px !important;
}

a { color: inherit; } 

a:hover {
	color: #ec008c !important;
	text-decoration: none !important;
}

body {
	margin:0;
}

.ie-fix {
	box-sizing: border-box;
	max-width: 100%;
}


.block-horizontal {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.content {
	/* display: flex;
	align-self: center;
	justify-content: center; */
	max-width: 1080;
	margin-left: 15;
	margin-right: 15;
	/* flex: 1; */
}

.body-link {
	color: #6dbedf !important;
}

p.red::selection {
  background: #6dbedf;
}
p.red::-moz-selection {
  background: #6dbedf;
}
p.blue::selection {
  background: #6dbedf;
}
p.blue::-moz-selection {
  background: #6dbedf;
}
p.yellow::selection {
  background: #6dbedf;
}
p.yellow::-moz-selection {
  background: #6dbedf;
}

::selection {
  background: #6dbedf; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #6dbedf; /* Gecko Browsers */
}

::-webkit-scrollbar {display:none;}


/* 
blue
273877

semiLightBlue
4a5b97

lightBlue
6dbedf



purple
51176e 

semiLightPurple
6d3c87

lightPurple
6dbedf
*/