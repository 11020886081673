
.wwd {
	padding-top: 40;
}


@media only screen and (max-width: 760px) {
  .wwd {
    padding-top: 20;
  } 
}