
.wave-hero {
  position: relative;
	display: flex;
	justify-content: center;
	max-width: 100%;
  width: 100%;
	overflow: hidden;
  height: auto;
}

.wave-hero-bg {
	object-fit: cover;
  width: 2178;
  height: 472;
}


.wave-hero-text-container {
  width: 2178;  
  height: 472;
  position: absolute;
}


@media only screen and (max-width: 900px) {
  .wave-hero-bg {
    width: 1521;
    height: 330;
  }
  
  .wave-hero-text-container {
    width: 1521;  
    height: 330;
  }
}


@media only screen and (max-width: 760px) {
  .wave-hero-bg {
    width: 1087;
    height: 236;
  }
  
  .wave-hero-text-container {
    width: 1087;  
    height: 236;
  }
}


@media only screen and (max-width: 500px) {
  .wave-hero-bg {
    width: 816;
    height: 177;
  }
  
  .wave-hero-text-container {
    width: 816;  
    height: 177;
  }
}