
.contact {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
	max-width: 100%;
	padding-top: 80;
	padding-bottom: 80;
	background-color: #cce9f4;
}

.contact h1 {
	width: 100%;
	text-align: left;
	color: #3434c8;
}

.contact-content {
	display: flex;
	justify-content: space-between;
	width:100%;
	margin-top: 40;
	padding-left: 20;
	padding-right: 20;
	max-width: 1080;

}

.contact-column {
	flex:1;
	height: 100%;
	width: 100%;
}

.contact-form {
	display: flex;
	flex-direction: column;
	margin-top: 67;
	width: 100%;
}

.contact-form-submit {
	width: 250;
	height: 50;
	background-color: #3434c8;
	color: white;
	font-family: 'Clear Sans';
	font-size: 15;
	border: 0px solid;
}

.contact-field {
	border: 0px solid;
	height: 60;
	width: 100%;
	max-width: 400;
	margin-bottom: 20;
	padding-left: 17;
	padding-right: 17;
}

.contact-message-field {
	border: 0px solid;
	width: 100%;
	max-width: 400;
	min-height: 200;
	margin-bottom: 20;
	padding: 17;

}

.contact-details {
	flex: 1;
}

.contact-title {
	text-align: left;
	margin-bottom: 20;
}


.leaflet-container {
	z-index: 1;
	width: 100%;
	height:400;
	margin-right: 20;
	margin-top: 20;
}


.contact-info {
	display: flex;
}

.contact-ways {
	text-align: left;
	margin-top: 10;
}

.contact-link {
	font-family: 'Clear Sans';
	cursor: pointer;
	text-align: left;
	white-space: pre-wrap;
	color: black;
}

@media only screen and (max-width: 900px) {
	.contact-field {
		max-width: 340;
	}
	
	.contact-message-field {
		max-width: 340;
	}
}


@media only screen and (max-width: 760px) {

	.contact {
		padding-top: 0;
	}

	.contact-column {
		margin-top: 40;
	}
	.contact-content {
		flex-direction: column;
		justify-content: center;
	}

	.contact-field {
		max-width: 400;
	}
	
	.contact-message-field {
		max-width: 400;
	}

	.leaflet-container {
		margin-left: 0;
		margin-right: 0;
		height:300;
	}

	.contact-form {
		margin-top: 40;
	}
}