.home-wwd-item {
  justify-content: center;
  margin-left: 20;
  margin-right: 20;
  margin-top: 50;
  max-width: 260;
  cursor: pointer;
}


.h-wwd-title {
  color: white;
  font-family: 'Clear Sans';
  font-size: 16;
}

.h-wwd-icon {
  max-width: 100;
  max-height: 100;
  margin-top: 30;
  margin-bottom: 30;
}

.h-wwd-info {
  color: white;
  font-family: 'Clear Sans';
  font-size: 14;
  margin-bottom: 20;
}


