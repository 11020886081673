.home-cs-item {
  justify-content: center;
  margin-top: 30;
  cursor: pointer;
}


.h-cs-title {
  color: #3434c8;
  font-weight: bold ;
  font-family: 'Clear Sans';
  margin-bottom: 10;
  width: 100%;
  font-size: 17;
  white-space: pre-wrap;
}

.h-cs-icon {
  max-height: 250;
  margin-top: 30;
  margin-bottom: 30;
}

.h-cs-info {
  color: #3434c8;
  font-family: 'Clear Sans';
  font-size: 14;
  margin-bottom: 50;
}


@media only screen and (max-width: 760px) {

  .home-cs-item {
    margin-top: 0;
  }  

}