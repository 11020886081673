
.plantlist {
	background-color: #cce9f4;
	padding-top: 100;
	padding-bottom: 60;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.plantlist-content {
  display: flex;
  flex-direction: row;
  max-width: 1060;
}

.plantlist-column {
  flex: 1;
  margin-top: 20;
  padding-bottom: 100;
}

.plantlist h1 {
  color: #3434c8;
}

.plantlist h5 {
  font-size: 15;
  color: #3434c8;
  font-family: 'Clear Sans';
  font-weight: bold;
  text-align: left;
}

.plantlist p {
  font-size: 14 !important;
   font-weight: normal !important;
  color: #3434c8;
  font-family: 'Clear Sans';
  text-align: left;
}

.plantlist-printers {
  max-width: 280;
}

@media only screen and (max-width: 760px) {
  .plantlist-content {
    flex-direction: column;
  } 

  .plantlist-column {
    margin-left: 20;
    margin-right: 20;
  }
}