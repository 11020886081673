.header {
	position: fixed;
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
  top: 0;
	z-index: 100;
	width: 100%;
}

.header-content {
	display: flex;
	justify-content: space-between;
	max-width: 1080;
	width: 100%;
	padding-left: 15;
	padding-right: 15;
	padding-top: 22;
	padding-bottom: 25;
	background-color: #ffffff;
}


.header-logo-name {
	max-height: 60;
	margin-bottom: 10;
}


.header-burger {
	display: none;
	color: #3434c8;
	font-size: 25;
	margin-top: 3;
}

.header-nav {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	justify-items: center;
}

.header-link {
	color: #3434c8;
	font-size: 14;
	letter-spacing: 1.8;
	font-family:"Clear Sans";
	margin-left: 50;
	text-decoration: none;
}

.header-link-active {
	color: #ec008c;
	letter-spacing: 1.8;
	margin-left: 50;
	font-family:"Clear Sans";
	font-size: 14;
	text-decoration: none;
}

.header-burger:focus {
	outline: 0;
}


.nav-animation-enter {
	opacity: 0;
}

.nav-animation-enter-active {
	opacity: 1;
	transition: opacity 350ms;
}

.nav-animation-exit {
	opacity: 1;
}

.nav-animation-exit-active {
	opacity: 0;
	transition: opacity 350ms;
}


@media only screen and (max-width: 990px) {
	.header-link {
		font-size: 14;
		margin-left: 20;
	}

}


@media only screen and (max-width: 860px) {
	.header-content {
		flex-direction: column;		
	}
	
	.header-logo {
		max-height: 45;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		min-width: 100%;
	}

	.header-logo-name {
		max-height: 45;
	}


	.header-nav {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-items: center;
		margin-top: 40;
		background-color: white;
	}
	
	.header-link {
		margin-left: 0;
		height:50;
	}

	.header-link-primary {
		margin-left: 0;
		padding-left: 30;
		padding-right: 30;
	}
	
	.header-link-active {
		margin-left: 0;
		height:50;
	}

	.header-burger {
		display: flex;
	}
}


@media only screen and (max-width: 760px) {

	.header-top-bar-covid {	
		font-size: 12;
	}
}