.footer {
	width: 100%;
	background-color: #3434c8;
	padding-top: 40;
	padding-left: 40;
	padding-right: 40;
	display: flex;
	justify-content: center;
	border-top-width: 1;
	border-top-color: #4b4acc;
	border-top-style: solid;
}

.footer-content-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	max-width: 1060;

}

.footer-content {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 25;
}


.footer a, .footer-link {
	font-family: 'Clear Sans';
	font-size: 14;
	cursor: pointer;
	color: white !important;
	white-space: pre-wrap;
}

.footer-copyright {
	padding-top: 14;
	width: 100%;
	border-top: 1px solid lightgray;
	display: flex;
	flex: 1;
	justify-content: space-between;
}

.footer-copyright-text {
	font-size: 13 !important;
	color: white;
}

.footer-pp-button {
	margin-right: 19;
	cursor: pointer;
	width: 140;
}

.footer-linkedin-button {
	width: 40;
	cursor: pointer;
	margin-left: 15;
}

.footer-iso-button {
	width: 150;
	margin-top: 20;
}

.footer-block-horizontal {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 82;
}

@media only screen and (max-width: 760px) {
	.footer-content {
		flex-direction: column;
	}

	.footer-block-horizontal {
		justify-content: flex-start;
		margin-top: 20;
	}

	.footer-logo {
		max-width: 90;
	}

	.footer-chunk {
		margin-top: 20;
	}

	.footer-copyright-text {
		max-width: 200;
	}
}