

.sus {
	background-color: #3434c8;
	padding-top: 60;
	padding-bottom: 60;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-size: cover;
}

.sus-content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	justify-items: center;
	max-width: 1060;
	width: 100%;
  margin-bottom: 90;
}

.sus b {
  font-family: 'Clear Sans';
  font-weight: normal;
  font-size: 15;
  color: #3434c8;
}


.sus-copy {
	flex: 0.5;
	align-items: flex-start;
	text-align: left;
}	

.sus-logos {
	display: flex;
	align-items: center;
	align-self: flex-start;
	flex: 0.5;
	flex-direction: column;
}

.sus-logo {
	max-width: 150;
	margin-top: 20;
	margin-bottom: 20;
}


@media only screen and (max-width: 860px) {

	.sus-content {
    flex-direction: column;
    align-items: center;
    justify-content: center; 
	}

  .sus-logos {
    align-self: center;
    flex: 1;
  }

	.sus-copy {
		margin-left: 20;
	}
}

@media only screen and (max-width: 760px) {

	.sus-content {
		display: inline-block;
	}
		
}
