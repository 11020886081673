.wwd-block {
  width: 100%;
  height: auto;
  padding-top: 50;
  padding-bottom: 50;
  padding-left: 20;
  padding-right: 20;
  display: flex;
	align-items: center;
	justify-items: center;
  flex-direction: column;
  color: white;
}

.wwd-block-dark {
  background-color: #3434c8;
}

.wwd-block-light {
  background-color: #cce9f4;
}

.wwd-block-content {
  display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	justify-items: center;
	max-width: 1060;
  margin-top: 50;
  margin-bottom: 40;
}

.wwd-block-img {
  max-width: 400;
}


.wwd-block-img-left {
  margin-right: 60;
}

.wwd-block-img-right {
  margin-left: 60;
}

.wwd-block b {
  font-family: 'Clear Sans';
  font-weight: 200;
  font-size: 14;
  white-space: pre-wrap;
  text-align: left;
  max-width: 400;
}


@media only screen and (max-width: 760px) {

  .wwd-block-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50;
    margin-bottom: 30;
  }

  .wwd-block h1 {
    font-size: 23 !important;
  }

  .wwd-block b {
    font-size: 13 !important;
    margin-bottom: 50;
    margin-left: 40;
    margin-right: 40;
  }

  .wwd-block-img-left {
    margin-right: 0;
    max-width: 300;
  }
  
  .wwd-block-img-right {
    margin-left: 0;
    max-width: 300;
  }
}