.app {
	text-align: center;
}

.app-content {
	margin-top: 110;
}

.app-content-no-banner {
	margin-top:73;
}


.content {
	margin-top: 10;
	padding-left: 15;
	padding-right: 15;
	margin-bottom: 50;
	max-width: 1080;
}

.content-container {
	display: flex;
	align-self: center;
	justify-content: center;
	max-width: 100%;
}

.content h1 {
	margin-top: 60;
	margin-bottom: 16;
}